.grey {
    color: gray;
}

.w-13 {
    width: 13%;;
}

/* .input-green-bg {
    background-color: #127B3F !important;
    border-color: #127B3F !important;
    color: white !important;
    font-size: 1vw;
} */

/* .title {
    position: absolute;
    justify-content: left;
    color: darkgreen;
    font-weight: bolder;
} */

.title-1 {
    /* position: absolute; */
    justify-content: left;
    color: rgb(221, 188, 4);
}

.sub-title {
    
    justify-content: left;
}

.yellow {
    color: rgb(221, 188, 4);
    justify-content: left;
}

.yellows {
    color: rgb(221, 188, 4);
    justify-content: left;
    text-decoration: underline;
    cursor: pointer;
}

.gray {
    color: gray;
}

.cont-attach {
    box-sizing: border-box;
    padding: 3% 5% 3% 3%;
    border-radius: 12px;
    background-color: rgb(252, 251, 251);
    box-shadow: inset 0 0 7px grey;
    width: 1150px;
    height: 200px;
}

.cont-attachments {
        box-sizing: border-box;
        padding: 3% 5% 3% 3%;
        border-radius: 12px;
        background-color: rgb(252, 251, 251);
        box-shadow: inset 0 0 7px grey;
        width: 300px;
        height: 200px;
}

.cont-downloads {
    box-sizing: border-box;
    padding: 3% 5% 3% 3%;
    border-radius: 12px;
    background-color: rgb(252, 251, 251);
    box-shadow: inset 0 0 7px grey;
    height: 220px;
    width: 1200px;
}
.attach-img {
    width: 10%;
}

.text-1 {
    justify-content: left;
}

.yellow-btn{
    background-color: #F4AB2B;
    border: none;
    color: aliceblue;
    border-radius: 7px;
    padding: 7px 20px 7px 20px;
    float: right;
    font-size: 1vw;
}
.yellow-button{
    /* font-size: 1vw; */
    background-color: #F4AB2B;
    color: white;
    border: 2px solid #F4AB2B;
    font-family: 'Gotham-Rounded-Bold';
    border-radius: 5px;  
    margin-left: inherit;
}
.g-btn{
    background-color: #127B3F;
    border: none;
    color: aliceblue;
    border-radius: 7px;
    padding: 7px 30px 7px 30px;
    font-size: large;
    float: right;
}