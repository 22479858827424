.branch-input {
    background: #ffffff;
    border: 1px solid #a4a4a5;
    border-radius: 5px;
  }

  .search-icon {
    display: inline;
    /* width: 7%; */
    height: 50%;
    margin-left: -10%;
    color: #e70689;
    cursor: pointer;
  }
  
  .branch-input {
    background: #ffffff;
    border: 1px solid #a4a4a5;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    padding-left: 5%;
    padding-right: 10%;
    z-index: 0;
  }

  .search-icon-cont {
    display: inline;
    width: 10%;
    color: #e70689;
    cursor: pointer;
  }

  .width-fill {
    width: -webkit-fill-available;
  }

  .height-fill {
    height: -webkit-fill-available;
  }
.alumni-table-wrapper td:nth-child(1),
.alumni-table-wrapper th:nth-child(1),
.alumni-table-wrapper td:nth-child(3),
.alumni-table-wrapper th:nth-child(3) {
  text-align: left !important;
  padding-left: 1% !important;
  width: 20%;
}

.alumni-table-wrapper td:nth-child(2),
.alumni-table-wrapper th:nth-child(2),
.alumni-table-wrapper td:nth-child(4),
.alumni-table-wrapper th:nth-child(4),
.alumni-table-wrapper td:nth-child(5),
.alumni-table-wrapper th:nth-child(5),
.alumni-table-wrapper td:nth-child(6),
.alumni-table-wrapper th:nth-child(6) {
  text-align: left !important;
  padding-left: 0 !important;
  /* width: 13%; */
}

.alumni-table-wrapper td:nth-child(7),
.alumni-table-wrapper th:nth-child(7) {
  /* text-align: center !important; */
  justify-content: center;
  width: 12%;
}