.page {
    overflow-x: hidden !important;
}

.name {
    font-size: larger;
}

.pink-bg {
    background-color: pink;
}

.pointer-cursor {
    cursor: pointer;
}

.button-yellow {
    background-color: #F4AB2B;
    color: aliceblue;
    border: none;
    font-size: 1vw;
    /* font-size: small; */
    border-radius: 5px;
}

.button-green {
    background-color: #0A4423;
    color: aliceblue;
    border: none;
    font-size: 1vw;
    border-radius: 5px;
}

.button-maroon {
    background-color: #8D171B;
    color: aliceblue;
    border: none;
    font-size: 1vw;
    border-radius: 5px;
}

.header-cont {
    background-color: #8D171B;
    color: white;
    /* margin-right: 10vw; */
    align-content: center;
    vertical-align: middle;
    border-radius: 10px;
    font-size: 2vw;
}

.form-cont-itc {
    box-sizing: border-box;
    padding: 3% 5% 3% 3%;
    background-color: white;
    box-shadow: inset 0 0 7px grey;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 90vh;
    width: 85vw;
}

.itc-db-container {
    width: 85vw;
    height: auto;
    /* background: pink; */
}

.user-profile {
    /* width: 80%; */
    width: 10vw;
    height: auto;
}

.profile-cont {
    display: grid;
    place-items: center;
    max-width: 25%;
}

.pio-profile {
    width: 8vw;
    height: auto;
}

.admin-name {
    font-size: 3vw;
}

.pio-name {
    font-size: 2.5vw;
}

.role-label {
    font-size: 2vw;
}

.campus-name {
    font-size: 2vw;
}

.pio-campus-name {
    font-size: 1.5vw;
}

.section-container {
    /* width: 75vw; */
    /* background-color: rgba(239, 236, 236, 0.678); */
    border-radius: 7px;
}

.gray-background {
    background-color: rgba(239, 236, 236, 0.678);
    border-radius: 7px;
}

.subsection-container {
    /* width: 49% !important; */
    background-color: rgba(239, 236, 236, 0.678);
    border-radius: 7px;
}

.section-header {
    font-size: 2vw;
    color: #0A4423;
    font-weight: bold;
    justify-content: center;
}

.section-body {
    font-size: 1vw;
    margin-left: 3% !important;
}

.yellow-box {
    background-color: #FCB840;
    border-radius: 7px;
    display: inline-block;
    vertical-align: middle;
}

.green-box {
    background-color: #127B3F;
    border-radius: 7px;
    text-align: center;
    vertical-align: middle;
}

.count-label {
    color: white;
    font-size: 3vw;
}

.subtitlex {
    color: white;
    font-size: 1.5vw;
}

.subtitlex1 {
    color: white;
    font-size: 1vw;
}

.top-row {
    background-color: pink;
}

.see-all-btn {
    background-color: #F4AB2B;
    border: none;
    color: aliceblue;
    border-radius: 7px;
    padding: 7px 20px 7px 20px;
    font-size: 1vw;
}

.admin-name2 {
    color: #0A4423;
    font-weight: bold;
    justify-content: center;
    font-size: 2vw;
}

.admin-name3 {
    color: #F4AB2B;
    justify-content: center;
    font-size: 2vw;
}

.yellow-btns {
    background-color: #F4AB2B;
    border: none;
    color: aliceblue;
    border-radius: 7px;
    padding: 7px 20px 7px 20px;
    font-size: 1vw;
    /* width: 12vw; */
}

.pink-bg {
    background: pink;
}

.maroon-dropdown {
    background-color: #7B1316;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 7px 10px 7px 10px;
    font-size: 1vw;
    width: 20vw;
}

.green-box {
    padding: 7px 20px 7px 20px;
    background-color: #0A4423;
    /* width: 18vw !important; */
    /* height: auto; */
    border-radius: 10px;
}

.admin-name1 {
    color: white;
    font-size: 2vw;
}

.from-label {
    font-size: 1vw;
    color: #0A4423;
    /* margin-top: 5%; */
}

.cont-color-grey {
    background-color: rgba(239, 236, 236, 0.678);
    border-radius: 7px;
}
.no-data-img {
    width: 45%;
}
@media screen and (min-width: 1000px) and (max-width: 2000px) {
    .header1-cont {
        background-color: #8D171B;
        max-width: 1440px !important;
        height: 140px !important;
        border-radius: 10px;
    }

    .form-contt {
        box-sizing: border-box;
        padding: 3% 5% 3% 3%;
        margin-top: 2%;
        /* margin-left: 100px; */
        background-color: white;
        box-shadow: inset 0 0 7px grey;
        height: 690px;
        overflow-y: scroll;
    }

    .conts-grey {
        background-color: rgba(239, 236, 236, 0.678);
        border-radius: 7px;
        width: 95%;
    }

    .conts-plaingreen {
        background-color: #127B3F;
        border-radius: 7px;
    }

    .conts-plainyellow {
        background-color: #FCB840;
        border-radius: 7px;
    }

    .conts-greyss {
        background-color: rgba(239, 236, 236, 0.678);
        border-radius: 7px;
        width: 103%;
    }

    .header2-cont {
        background-color: white;
        max-width: 1440px !important;
        height: px !important;
        border-radius: 10px;
    }

    .header3-cont {
        background-color: white;
        max-width: 1440px !important;
        height: 300px !important;
        border-radius: 10px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 5000px) {
    .container-cont {
        /* background-color: #838d17; */
        max-width: 300px !important;
        height: 130px !important;
        border-radius: 10px;

    }

    .container-cont1 {
        background-color: #0A4423;
        max-width: 290px !important;
        height: 147px !important;
        border-radius: 10px;
    }

    .user-profile {
        /* position: absolute; */
        width: 130px !important;
        justify-self: left;
    }

    .size {
        font-size: 2vw;
    }

    .admin-name {
        /* position: absolute; */
        color: aliceblue;
        justify-content: center;
    }

    .admin-name1 {
        /* position: absolute; */
        color: aliceblue;
        /* font-size: xx-large; */
        justify-content: center;
    }

    .admin-name3 {
        /* position: absolute; */
        color: #F4AB2B;
        /* font-size: sma; */
        justify-content: center;
    }

    .subtitlex {
        /* position: absolute; */
        color: white;
        justify-content: left;
    }

    .subtitlex1 {
        /* position: absolute; */
        font-size: small;
        color: white;
        justify-content: left;
    }
}

.no-display {
    display: none;
}

.report-table {
    width: 90vw;
        font-size: 1vw;
}

.report-logo-container {
    width: 90vw;
    align-content: center;
}

.report-logo {
    width: 8%;
}

@media screen {
    .onlyPrint {
        display: none !important;
    }
}
