/**Table**/
$bg-color: #ffff;
$primary-color: #678de6;
$light-color: #fff;
$border-color: #ffff;
$header-bg-color: #ffff;
$header-text-color: #45517d;
$ff-primary: "Poppins", sans-serif;

@mixin break {
  // thead {
    //   display: none;
    // }

  tr {
    display: block;
    margin-bottom: 5px;
  }

  td {
    display: flex;
    position: relative;
    padding-left: 170px;
    text-align: left;
    // border-bottom: 0;

    &:last-child {
      border-bottom: 1px solid $border-color;
    }

    &::before {
      content: attr(data-heading);
      position: absolute;
      top: 0;
      left: 0;
      width: 160px;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: var(--primary-color);
      color: $light-color;
      font-size: 0.75rem;
      padding: 0 5px;
      // justify-content: center;
    }
  }

  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 50px;
    
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: $ff-primary;
}

.table-container {
  max-width: 1500px;
  width: 100% !important;
  margin: 0 auto 10px;
  background-color: #ffff;
  border-radius: 10px;
  margin-top: 1% !important;

  table tr:last-child td:first-child {
    border-bottom-left-radius: 0px;
  }

  table tr:last-child td:last-child {
    border-bottom-right-radius: 0px;
  }

  table th:first-child {
    -moz-border-radius: 8px 0 0 0;
    -webkit-border-radius: 8px 0 0 0;
  }

  table th:last-child {
    border-radius: 0 8px 0 0;
    -moz-border-radius: 0 8px 0 0;
    -webkit-border-radius: 0 8px 0 0;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    font-size: 90%;

    thead {
      tr {
        background-color: transparent;
      }
    }

    td,
    th {
      border: none;
      // padding: 4px;
        text-align: left !important;
        padding: 1%;
    }

    th {
      font-family: "Montserrat-Bold";
      background-color: var(--primary-color);
      color: var(--light-color);
      font-weight: bold;
      // padding: 1rem;
    }

    td {
      background-color: #ffffff;
      border-bottom: 1.5px solid #9c9a9a;
      // cursor: pointer;
      // font-size: 15px;
      height: 70px;
    }

    tr:hover td {
      background-color: var(--secondary-light-color) !important;
    }

    .first-index {
      border-right: 1.5px solid #9c9a9a !important;
    }

    &--break-lg {
      @media (max-width: 991px) {
        @include break;
      }
    }

    &--break-md {
      @media (max-width: 767px) {
        @include break;
      }
    }

    &--break-sm {
      @media (max-width: 575px) {
        @include break;
      }
    }
  }
}

.no-results-found {
  width: 140%;
  text-align: center;
}

.search-table-icon {
  padding-right: 2% !important;
}

.sort-icon {
  margin-left: 2%;
}

.active-sort {
  color: var(--secondary-color);
}

/** Status **/

.Active {
  color: var(--active);
}

.Inactive {
  color: var(--inactive);
}

// SPINNER
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #8D171B; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  justify-content: center;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.action-btn {
  border: none;
  width: 100%;
    // width: 50% !important;
  border: 1px solid var(--secondary-color);
  background: yellow;
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.1);
  color: var(--secondary-color);
  font-size: 20% !important;
}

.table-dropdown {
    background-color: #f4aa2b8a !important;
    color: #BE591A !important;
    // width: fit-content !important;
}

.view-btn {
  text-align: center !important;
  border: none;
  width: 5vw !important;
  height: 50%;
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.1);
  color: var(--secondary-color);
  border-radius: 3px;
}

@media print {
  @page {
    // size: landscape;
    size: auto;
    margin: 16mm 16mm 16mm 16mm;
    // background-color: pink;
  }

  .noprint {
    display: none;
  }
}

.essay-table-label{
  text-align: center;
  font-size: 16px;
}
