.validity-error {
    color: var(--error-color);
    font-size: 11px;
	z-index: 10;
}

div.callout {
    font-size: 12px;
	background-image: -moz-linear-gradient(top, #127B3F,#127B3F);
	position: relative;
	color: red;
    width: 100%;
    padding: 2px;
	border-radius: 3px;
	border: none;
	z-index: 10;
	text-align: left !important;
}
/* 
.callout::before {
	content: "";
	width: 0px;
	height: 0px;
	border: 0.8em solid transparent;
	position: absolute;
	z-index: 10;
}

.callout.bottom::before {
	left: 45%;
	top: -19px;
	border-bottom: 10px solid #127B3F;
	z-index: 10;
} */

.error-icon {
	padding-right: 3%;
	color: #dc3444;
}

/** Media Queries **/

/** Mobile **/
@media (max-width: 1200px) {
	div.callout {
		width: 100%;
	}
}





