/* .title {
    position: absolute;
    justify-content: left;
    color: black;
    font-weight: bolder;
} */

.maron-btn {
    background-color: #7B1316;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 40px 10px 40px;
    /* width: 30px; */
}
.date{
    border: none;
    background-color: #D9D9D9;
    border-radius: 5px;
    padding: 7px 10px 7px 10px;
}
.branch-input {
    background: #ffffff;
    border: 1px solid #a4a4a5;
    border-radius: 5px;
  }

  .branch-icon {
    display: inline;
    width: 10%;
    margin-left: -10%;
    color: #e70689;
    cursor: pointer;
  }
  
  .branch-input {
    background: #ffffff;
    border: 1px solid #a4a4a5;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    padding-left: 5%;
    padding-right: 10%;
    z-index: 0;
  }

  .search-icon-cont {
    display: inline;
    width: 10%;
    color: #e70689;
    cursor: pointer;
  }
.scholarship-wrapper td:nth-child(1),
.scholarship-wrapper th:nth-child(1),
.scholarship-wrapper td:nth-child(2),
.scholarship-wrapper th:nth-child(2),
.scholarship-wrapper td:nth-child(3),
.scholarship-wrapper th:nth-child(3),
.scholarship-wrapper td:nth-child(4),
.scholarship-wrapper th:nth-child(4),
.scholarship-wrapper td:nth-child(6),
.scholarship-wrapper th:nth-child(6) {
  text-align: left !important;
  padding-left: 1% !important;
  /* width: 20%; */
}

.scholarship-wrapper td:nth-child(5),
.scholarship-wrapper th:nth-child(5) {
  text-align: right;
  padding: 0 !important;
}