/* .right {
    justify-content: right;
    float: right;
    width: 200px;
} */
.edit-icon {
    width: 20px;
}
/* .green {
    color: #0A4423;
    font-weight: 900;
} */
.green-btn{
    background-color: #0A4423;
    border: none;
    color: aliceblue;
    border-radius: 7px;
    padding: 7px 20px 7px 20px;
    font-size: large;
    float: right;

}

.remark-text{
    font-size: 10px;
    line-height: 1.6;
}

.remark-header{
    font-size: 12px;
}
