.page {
  overflow-x: hidden !important;
  }
  
  .login-container {
    height: 100vh !important;
    --bs-gutter-x: 0 !important;
    overflow-y: auto !important;
    background-image: url("../../Assets/Images/Login/Background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  
  /** Left Portion **/
  .login-header {
    display: flex;
    justify-content: center;
    animation-delay: 3s;
    -webkit-animation-delay: 3s;
    animation: fadein 5s;
  -moz-animation: fadein 5s;
    /* Firefox */
    -webkit-animation: fadein 5s;
    /* Safari and Chrome */
    -o-animation: fadein 5s;
    /* Opera */
  }
  
  .login-logo {
    width: 20%;
    margin-top: 7%;
    padding-right: 5%;
    z-index: 1;
    object-fit: contain;
  }

.login-single {
  width: 15%;
  margin-top: 7%;
  padding-right: 5%;
  z-index: 1;
  object-fit: contain;
}

.single-icon {
  left: 10%;
  width: 10%;
  margin-left: 10%;
}


.login-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30%;
  z-index: 0;
}

.login-form {
  color: #ffff;
  margin: 14%;
  animation: fadein 2s;
  -moz-animation: fadein 2s;
    /* Firefox */
    -webkit-animation: fadein 2s;
    /* Safari and Chrome */
    -o-animation: fadein 2s;
    /* Opera */
  }
  
  .welcome-banner {
    font-weight: bold;
    font-size: 60px;
    margin-top: 13%;
  }
  
  .instructions-banner {
    font-size: 20px;
    max-width: 75%;
    text-align: left;
    margin-left: 3%;
    margin-bottom: 13%;
  }
  
  .login-input {
    background: #ffffff;
    border: 2px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 27px;
    width: 103%;
    margin-top: 5%;
    padding: 2%;
    padding-left: 12%;
    z-index: 0;
  }
  
  .remember-me-checkbox {
    margin-right: 1%;
  }
  
  .remember-me-input {
    margin-top: 3%;
    margin-left: 3%;
  }
  
  .input-cont {
    margin-top: 2%;
  }
  
  .icon-cont {
    display: flex;
    background-color: var(--primary-color);
    padding: 0.5%;
    justify-content: center;
    border-radius: 8px;
    margin-left: -41px;
  }
  
  .icon-cont-password {
    display: flex;
    background-color: var(--primary-color);
    padding: 0.85%;
    justify-content: center;
    border-radius: 8px;
    margin-left: -41px;
  }
.user-login-icon,
.eye-icon {
  margin-top: 18%;
  width: 60%;
  height: 60%;
}

.password-icon {
  position: relative;
  float: left;
  width: 5%;
  margin-top: -33px;
  margin-left: 5%;
  color: #4c4c4c;
  cursor: pointer;
  z-index: 3;
}

.eye-slash {
  width: 4.7%;
}

.login-header,
.header-label-cont {
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.5s;
  animation: slide 0.5s forwards;
  animation-delay: 0.5s;
}

.header-label-cont {
  margin-top: 2%;
}

.login-header-label {
  text-transform: uppercase;
  color: var(--secondary-color);
  letter-spacing: 0.1px;
  font-weight: 400;
}

/** Button **/
.login-cont {
  padding-right: 30% !important;
}

.username-input {
  width: 30%;
  border: none;
  background: rgba(203, 182, 183, 0.3);
  border-radius: 8px;
  padding: 0.8%;
  padding-left: 1%;
  box-shadow: 0px 3px 2px 0px rgba(145, 145, 145, 0.76);
  -webkit-box-shadow: 0px 3px 2px 0px rgba(145, 145, 145, 0.76);
  -moz-box-shadow: 0px 3px 2px 0px rgba(145, 145, 145, 0.76);
}

.forgot-password {
  width: 30%;
  text-decoration: underline;
  color: var(--tertiary-color);
  font-size: 13px;
  margin-top: 0.5%;
  margin-left: 1%;
  cursor: pointer;
}

.login-btn {
  background-color: var(--primary-color);
  border: none;
  width: 50%;
  color: var(--light-color);
  padding: 10px;
  border-radius: 9px;
}

/** Media Queries **/

/** Mobile **/
@media (max-width: 1000px) {

  .login-header-label {
    font-size: 12px;
    margin-top: -6%;
    margin-bottom: 10%;
  }

  .username-input {
    width: 60%;
  }

  .forgot-password {
    width: 60%;
    margin-left: 5%;
    margin-top: 2%;
  }

  .login-btn {
    width: 30%;
  }

  .icon-cont {
    margin-left: -34px;
  }

  .eye-icon {
    padding: 2.5%;
    width: 80%;
  }

  .icon-cont-password {
    margin-left: -30px;
    padding: 2.3%;
    font-size: 13px;
  }

  .login-header {
    margin-top: 30%;
  }

}

/** 4K **/
@media (min-width: 2560px) {}

/** Animation **/
@-webkit-keyframes slide {
  100% {
    margin-left: 5%;
  }
}

@keyframes slide {
  100% {
    margin-left: 5%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

    to {
      opacity: 1;
    }
                }

@-moz-keyframes fadein {

  /* Firefox */
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
                }

@-webkit-keyframes fadein {

  /* Safari and Chrome */
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
                }

@-o-keyframes fadein {

  /* Opera */
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
                }