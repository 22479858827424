.mt-10 {
  margin-top: 10px;
}

.maroon-btn {
    background-color: #7B1316;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 7px 10px 7px 10px;
    font-size: 1vw;
}

.orange-btn {
  background-color: #F4AB2B;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 7px 10px 7px 10px;
  font-size: 1vw;
  /* width: 30px; */
}

.date{
    border: none;
    background-color: #D9D9D9;
    border-radius: 5px;
    padding: 7px 10px 7px 10px;
}

.branch-input {
    background: #ffffff;
    border: 1px solid #a4a4a5;
    border-radius: 5px;
  }

  .branch-icon {
    display: inline;
    width: 10%;
    margin-left: -10%;
    color: #e70689;
    cursor: pointer;
  }
  
  .branch-input {
    background: #ffffff;
    border: 1px solid #a4a4a5;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    padding-left: 5%;
    padding-right: 10%;
    z-index: 0;
  }

  .search-icon-cont {
    display: inline;
    width: 10%;
    color: #e70689;
    cursor: pointer;
  }

  .white-text {
    color: white;
  }

  .add-btn {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 0.75vw;
  }
.announcement-wrapper td:nth-child(2),
.announcement-wrapper th:nth-child(2) {
  text-align: left !important;
  padding-left: 1% !important;
  width: 30%;
}

.announcement-wrapper td:nth-child(1),
.announcement-wrapper th:nth-child(1),
.announcement-wrapper td:nth-child(3),
.announcement-wrapper th:nth-child(3),
.announcement-wrapper td:nth-child(4),
.announcement-wrapper th:nth-child(4) {
  text-align: left !important;
  padding-left: 1% !important;
  /* width: 20%; */
}

.announcement-wrapper td:nth-child(5),
.announcement-wrapper th:nth-child(5) {
  justify-content: center;
  width: 15%;
}