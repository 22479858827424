.branch-input {
    background: #ffffff;
    border: 1px solid #a4a4a5;
    border-radius: 5px;
  }

  .search-icon {
    display: inline;
    /* width: 7%; */
    height: 50%;
    margin-left: -10%;
    color: #e70689;
    cursor: pointer;
  }
  
  .branch-input {
    background: #ffffff;
    border: 1px solid #a4a4a5;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    padding-left: 5%;
    padding-right: 10%;
    z-index: 0;
  }

  .search-icon-cont {
    display: inline;
    width: 10%;
    color: #e70689;
    cursor: pointer;
  }

  .width-fill {
    width: -webkit-fill-available;
  }

  .options {
    display: flex;
    /* align-items:flex-end ; */
    justify-content:right ;
    /* background-color: blue; */
  }

  .from_date {
      background-color: #127B3F !important;
      border-color: #127B3F !important;
      font-size: 0.90vw !important;
      color: white;
  }

  .project-title {
    font-size: 3vw;
    font-family: 'Gotham-Rounded-Bold';
    color: #0A4423 ;
    justify-content: left;
  }
.project-wrapper td:nth-child(1),
.project-wrapper th:nth-child(1),
.project-wrapper td:nth-child(2),
.project-wrapper th:nth-child(2),
.project-wrapper td:nth-child(3),
.project-wrapper th:nth-child(3),
.project-wrapper td:nth-child(4),
.project-wrapper th:nth-child(4),
.project-wrapper td:nth-child(5),
.project-wrapper th:nth-child(5) {
  text-align: left !important;
  padding-left: 1% !important;
  /* width: 20%; */
}

.project-wrapper td:nth-child(6),
.project-wrapper th:nth-child(6) {
  text-align: left !important;
  padding-left: 1% !important;
  width: 25%;
}

.project-wrapper td:nth-child(7),
.project-wrapper th:nth-child(7) {
  justify-content: center;
  width: 13%;
}