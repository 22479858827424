/* .login-container {
    height: 100vh !important;
    --bs-gutter-x: 0 !important;
    overflow-y: auto !important;
    background-image: url("../../Assets/Images/Login/Background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
*/
  
.form-cont {
    box-sizing: border-box;
    padding: 2% 2% 2% 2%;
    margin-top: 2%;
    background-color: white;
    box-shadow: inset 0 0 7px grey;
    width: 90vw;
        height: 90vh;
    margin-left: 8vw;
    box-shadow: inset 0 0 7px grey;
    overflow-y: scroll;
    justify-content: center !important;
    /* background-position: center; */
}

.center-form-cont{
    justify-content: center;
}

.form-conts {
    /* border: 3px solid #B9B9B9; */
    box-sizing: border-box;
    padding: 3% 5% 3% 3%;
    margin-top: 2%;
    margin-left: 150px;
    background-color: white;
    /* box-shadow: inset 0 0 7px grey; */
    height: 690px;
    /* overflow-y: scroll; */
}
  

.required-icon {
    color: #FA1010;
    margin-left: 1%;
}
  
.title {
    text-transform: capitalize;
    font-family: 'Gotham-Rounded-Bold';
    color: #0A4423 ;
}
  
.input-title {
    text-transform: capitalize;
    font-family: 'Gotham-Rounded-Bold';
    font-style: italic;
    color: #127B3F;
}
  
.input-subtitle {
    font-family: 'Gotham-Rounded-Bold';
    margin-bottom: 0;
    text-align: left;
    color: #0A4423;
    font-size: 1vw;
}

  .custom-control-label {
    font-size: 1vw;
  }
  
  .input-subtitle-grey {
      font-family: 'Gotham-Rounded-Bold';
      margin-bottom: 0;
      text-align: left;
      color: #545454;
  }
  
  .input-shadow {
      box-shadow: 0 4px 4px -2px grey;
  }
  
  /* buttons */
  .btn-green {
      background-color: #0A4423;
      color: white;
      border: 2px solid #0A4423;
      font-family: 'Gotham-Rounded-Bold';
    border-radius: 0.5em;
      margin-left: inherit;
  }

  .back-btn {
      background-color: white;
      color: #0A4423;
      border: 2px solid #0A4423;
      border-radius: 0.5em;
  }
  .btn-gren {
      background-color: #0A4423;
      color: #F4AB2B;
      border: 2px solid #F4AB2B;
      font-family: 'Gotham-Rounded-Bold';
      border-radius: 5px;  
      margin-left: inherit;
      padding: 5px 15px;
  }

    .btn-yellow-orange {
        background-color: #f4aa2bb3;
        color: white;
        border: 2px solid #F4AB2B;
        font-family: 'Gotham-Rounded-Bold';
        border-radius: 5px;  
        margin-left: inherit;
    }
    
    .btn-maroon {
        background-color: #7b1316bb;
        color: white;
        border: 2px solid #7B1316;
        font-family: 'Gotham-Rounded-Bold';
        border-radius: 5px;  
        margin-left: inherit;
    }
  
  .pt-15 {
      padding-top: 15px;
  }
  
  /* MARGINS */
  .mr-5 { 
      margin-right: 5px;
  }
  
  .w-100 {
      width: 100px;
  }
  
  .left {
      justify-content: left;
      text-align: left;
  }
  
  .right {
      justify-content: right;
      float: right;
  }
  
  .form-control {
    font-size: 1vw !important;
    box-shadow: 0 4px 4px -2px grey;
  }
  
  @media screen and (min-width: 320px) and (max-width: 480px) {
      .form-group {
          font-size: 10px;
          margin-right: -7px;
      } 
  
      .input-subtitle {
          font-family: 'Gotham-Rounded-Bold';
          margin-bottom: 0;
          text-align: left;
          color: #0A4423;
          /* font-size: 0.80rem !important; */
          font-size: 1vw;
      }
  
      .input-subtitle-grey {
          font-family: 'Gotham-Rounded-Bold';
          margin-bottom: 0;
          text-align: left;
          color: #545454;
        /* font-size: 0.80rem !important; */
            font-size: 1vw;
      }
  
      .btn-green {
          width: 20vw !important;
          margin-left: -40px;
      }
  
      .col-4 {
          width: 95% !important;
          margin-bottom: 10px;
      }
  
      .col-2 {
          width: 35%;
          /* margin-bottom: 10px; */
      }
  
      .col-3 {
          width: 50%;
          margin-bottom: 10px;
      }
      
      .col-6 {
          /* width: 45%; */
          width: 90%;
      }
  }
  
  
  @media screen and (max-width: 415px) {
      .container {
          margin-left: 0px;
      }

      .w-98 {
        width: 98% !important;
      }
  
      .form-group {
          font-size: 10px;
          margin-right: -7px;
      } 
  
      .type {
          font-size: 10px;
          width: 100%;
      }
  
      .search-icon {
          margin-right: 3vw;
      }
  
      .col-4 {
          width: 90% !important;
          margin-bottom: 10px;
      }
  
      .col-2 {
          width: 100% !important;
          margin-bottom: 10px;
      }
  
      .col-3 {
          width: 90% !important;
          margin-bottom: 10px;
      }
      
      .col-6 {
          /* width: 45%; */
          width: 100% !important;
          margin-bottom: 10px;
      }
      .row-form {
          width: 350px !important;
      }
  }
  
  @media screen and (max-width: 550px) {
      .header {
          font-size: 7vw;
      }
  
      .container {
          max-width: 230vw;
          margin: 5px;
          margin-left: 0px;
          /* margin-left: 13px; */
      }
  
      .form-group {
          font-size: 10px;
          margin-right: -7px;
      } 
  
      #country {
          font-size: 10px;
      }

      .type {
          font-size: 10px;
      }
  
      .form-select-md {
          font-size: 10px;
      }
  
      .input-group-text {
          font-size: 10px;
      }
  
      .asterisk {
          width: 85vw;
      }
  
  }

  @media screen and (max-width: 1024px) {
    .form-control {
        font-size: 1.5vw !important;
    }

    .input-subtitle {
        font-size: 1.5vw !important;
    }
  }