.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .wrapper {
    display: flex;
    align-items: center;
  }
  
  .barContainer {
    flex: 1;
    height: 8px;
    background: #dfdfe7;
    border-radius: 12px;
    overflow: hidden; /* Crucial part */
  }
  
  .textValue {
    margin-left: 8px;
    width: 42px;
    text-align: right;
  }

  .g{
    color: #0A4423;
    font-size: 25px;
  }
  
  .fillerBackground {
    height: inherit;
    transition: "width 2s ease-i-out";
    background: linear-gradient(90deg, purple, darkorange);
  }
  
  .filler {
    transition: "width 2s ease-i-out";
    height: inherit;
    border-radius: inherit;
    overflow: hidden;
  }
  