
.employment-title {
    font-size: 2vw;
    font-family: 'Gotham-Rounded-Bold';
    color: #0A4423 ;
    justify-content: left;
}

.table-cont {
    background-color: pink;
}

.employability-wrapper td:nth-child(1),
.employability-wrapper th:nth-child(1),
.employability-wrapper td:nth-child(2),
.employability-wrapper th:nth-child(2),
.employability-wrapper td:nth-child(3),
.employability-wrapper th:nth-child(3),
.employability-wrapper td:nth-child(4),
.employability-wrapper th:nth-child(4),
.employability-wrapper td:nth-child(5),
.employability-wrapper th:nth-child(5),
.employability-wrapper td:nth-child(6),
.employability-wrapper th:nth-child(6),
.employability-wrapper td:nth-child(7),
.employability-wrapper th:nth-child(7) {
    text-align: left !important;
    padding-left: 1% !important;
    /* width: 20%; */
}

.studies-wrapper td:nth-child(1),
.studies-wrapper th:nth-child(1),
.studies-wrapper td:nth-child(2),
.studies-wrapper th:nth-child(2),
.studies-wrapper td:nth-child(3),
.studies-wrapper th:nth-child(3),
.studies-wrapper td:nth-child(4),
.studies-wrapper th:nth-child(4),
.studies-wrapper td:nth-child(5),
.studies-wrapper th:nth-child(5) {
    text-align: left !important;
    padding-left: 1% !important;
    /* width: 20%; */
}