.ml-100 {
    margin-left: 100px;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-3 {
    margin-left: 10px !important;
}

.p-40 {
    padding: 40px;
}

.pl-0 {
    padding-left: 0px !important;
}


.pr-0 {
    padding-right: 0px !important;
}

.p-20 {
    padding: 20px;
}

.p-50 {
    padding: 50px;
}

.pt-10 {
    padding-top: 10px;
}

.w-180 {
    width: 180%;
}

.-w-webkit {
    width: max-content;
}

.dash-sub-info {
    color: #7B1316;
    font-family: 'Gotham-Rounded-Bold';
    text-align: left;
}

.dash-info-icon {
    width: 60px !important;
    height: 40px !important;
    
}

.dashboard-user-icon {
    width: 75%;
}

.center {
    justify-content: center;
    text-align: center;
}

.top-br {
    border: 1px solid white;
    margin-top: 1vw;
    border-top-right-radius: 80px !important;
    border-top-left-radius: 80px !important;
}

.dash-container {
    position: absolute;
    margin-top: 150px !important;
}

.dash {
    /* position: absolute; */
    margin-top: 0px !important;
}

.user-icon {
    position: relative;
    margin-top: -100px !important;
}

.dash-info {
    background-color: #7B1316;
    color: #F4AB2B;
    /* height: 40px; */
        font-size: 100%;
    text-align: center;
    /* padding-top: 7px; */
        /* width: inherit; */
        padding: 2%;
}

.dark-grey {
    background-color: #D9D9D9;
    height: auto;
}

.light-grey {
    background-color: #F0F0F0;
    height: auto;
}

.rad {
    border-radius: 10px;
}

.rad-right {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.grey {
    color: #D9D9D9A6;
    background-color: #D9D9D9;
    height: 20px;
   
}

.row-height {
    height: 50px !important;
}

.sablay-img{
    width: 103% !important;
    /* height: 20%; */
}

.dash-containers {
    /* position: absolute; */
    z-index: 1;
    margin-top: 0px !important;
}

.dash {
    /* position: absolute; */
    margin-top: 0px !important;
}

.student-picture {
    size: 10%;
}

.section-header {
    font-size: 50%;
}

.profile-header-container {
    background-color: #0A4423;
    border-radius: 10em;
}

.profile-header {
    color: white;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.profile-subheader {
    background-color: #7B1316;
    color: #F4AB2B;
    border-radius: 10em;
    padding: 0.5%;
}

.profile-table-header {
    background: white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10em;
        color: #0A4423;
    }
    
    .profile-table th {
        padding: 3px;
    }
    
    .entire-page {
        background: #FFFAFA;
}
@media screen and (max-width: 550px) {
    .ml--50 {
        margin-left: -50px !important;
    }

    .membership {
        font-size: 14px !important;
    }

    .container {
        width: 90% !important;
    }

    .dash-container {
        position: absolute;
        margin-top: 70px !important;
    }
    
    .dashboard-user-icon {
        height: 100px !important;
        margin-top: 60px !important; 
    }

    .dash-info {
        background-color: #7B1316;
        color: #F4AB2B;
        height: 40px !important;
        text-align: center;
        padding-top: 7px;
        width: 80% !important;
    }
    
    .p-50 {
        padding: 10px;
    }

    .pt-5 {
        padding-top: 1rem !important;
    }

    .h3 {
        font-size: 20px !important;
    }
    .p-40 {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 30px;
        padding-right: 10px;
    }

    .dark-grey {
        background-color: #D9D9D9;
        height: auto;
    }
    /* .top-br {
        border: 1px solid white;
        border-top-right-radius: 50px !important;
        border-top-left-radius: 50px !important;
        margin-left: 11px !important;
    } */

    .dash-sub-info{
        color: #7B1316;
        font-family: 'Gotham-Rounded-Bold';
        width: 10% !important;
    }
    .container {
        /* max-width: 1440px !important; */
        height: auto;
    }

    .row-height {
        height: 50px !important;
        margin-top: 50px !important;
    }

    .h-150 {
        height: 150px;
    }

    .user-icon {
        height: 50px ;
    }

    .tableFooter {
        min-width: 200px !important;
      }
}

@media screen and (min-width: 1400px) {
    .ml-0 {
        margin-left: 0px !important;
    }
    .container {
        max-width: 1440px !important;
        height: auto;
    }
    .w {
        width: 100%;
    }
    
    .ml-10 {
        margin-left: 10px !important;
    }

    .pt-5>* {
        padding-top: 3rem !important;
    }
}




