.grey {
    color: gray;
}

.w-13 {
    width: 13%;;
}

.input-green-bg {
    background-color: #127B3F !important;
    border-color: #127B3F !important;
    color: white !important;
    font-size: 1vw;
}

.message {
    font-size: 15px !important;
    font-style: italic;
}

.header-2 {
    font-size: medium;
    font-weight: bolder;
}

.click-btn {
    font-size: small;
    font-weight: lighter;
    border-radius: 7px;
    color: white;
    /* border-color: #127B3F; */
    border: none;
    background-color: #127B3F;
    padding: 10px 16px 10px ;
    margin-right: 5px;
    width: -webkit-fill-available;
    
    /* border-bottom-color: darkgray; */
}

.plans-btn {
    font-size: small;
    font-weight: lighter;
    border-radius: 7px;
    /* border-color: darkgray; */
    background-color: white;
    padding: 10px 16px 10px ;
    margin-right: 5px;
    width: -webkit-fill-available;
}

.answer-txt {
    border-color: gray;
    box-shadow: 0 4px 4px -2px grey;
    padding: 10px 16px 100px ;
    border-radius: 6px;
    height: fit-content;
}

.white{
    color:azure;
}
.yellow-bg {
    background-color: yellow;
}

.blue-bg {
    background-color: blue;
}

.orange-bg {
    background-color: orange;
}
.smaller-img {
    width: 20% !important;
}

.centered-image {
    display: block;
    margin: 0 auto;
}
.question-header {
    background-color: var(--primary-color);
    color: white;
    border-radius: 0.5em;
}

.response-container {
    background-color: hsla(0, 0%, 76.9%, 0.2196078431372549);
    border-radius: 0.5em;
    height: 50vh !important;
    max-height: 50vh !important;
    overflow-y: scroll;
}